import { DesktopTimePicker } from '@mui/lab'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { WorkerFormUserDataInput } from 'client/form/userFormTypes'
import { differenceInMinutes } from 'date-fns/esm'
import React, { useEffect, useState } from 'react'
import { SignatureInput } from './SignatureInput'

interface Props {
  userData: WorkerFormUserDataInput
  onChange: (
    field: keyof WorkerFormUserDataInput,
  ) => (value: WorkerFormUserDataInput[typeof field] | null) => void
  errors: {
    [key: string]: string
  }
}

export const UserDataStep: React.FC<Props> = ({
  userData,
  onChange,
  errors,
}) => {
  const [signatureOpen, setSignatureOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!userData.arrivalTime) return
    const difference = differenceInMinutes(
      userData.departureTime,
      userData.arrivalTime,
    )
    const hourDifference = Math.round((difference / 60) * 100) / 100
    if (!isNaN(difference) && difference > 0)
      onChange('hoursOfWork')(hourDifference)
  }, [userData.arrivalTime, userData.departureTime])

  return (
    <>
      <SignatureInput
        open={signatureOpen}
        close={() => setSignatureOpen(false)}
        setSignature={onChange('pumpConfirmation')}
      />
      <Box
        my={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={3}
      >
        <Box display="flex" gap={3} width="100%">
          <TextField
            fullWidth
            label="Ilość m³"
            type="number"
            value={userData.cubicMetersAmount}
            onChange={(e) => onChange('cubicMetersAmount')(e.target.value)}
            error={Boolean(errors?.cubicMetersAmount)}
            helperText={errors?.cubicMetersAmount}
            onFocus={() =>
              `${userData.cubicMetersAmount}` === '0' &&
              onChange('cubicMetersAmount')('')
            }
            onBlur={() =>
              !userData.cubicMetersAmount && onChange('cubicMetersAmount')('0')
            }
          />

          <TextField
            fullWidth
            label="Czas pracy"
            type="number"
            value={userData.hoursOfWork}
            onChange={(e) => onChange('hoursOfWork')(e.target.value)}
            error={Boolean(errors?.hoursOfWork)}
            helperText={errors?.hoursOfWork}
            onFocus={() =>
              `${userData.hoursOfWork}` === '0' && onChange('hoursOfWork')('')
            }
            onBlur={() => !userData.hoursOfWork && onChange('hoursOfWork')('0')}
          />
          <TextField
            fullWidth
            label="Ilość km"
            type="number"
            value={userData.kilometersAmount}
            onChange={(e) => onChange('kilometersAmount')(e.target.value)}
            error={Boolean(errors?.kilometersAmount)}
            helperText={errors?.kilometersAmount}
            onFocus={() =>
              `${userData.kilometersAmount}` === '0' &&
              onChange('kilometersAmount')('')
            }
            onBlur={() =>
              !userData.kilometersAmount && onChange('kilometersAmount')('0')
            }
          />
        </Box>
        <Box display="flex" gap={3} width="100%">
          <FormControl fullWidth>
            <InputLabel id="wash-label">Mycie na budowie</InputLabel>
            <Select
              label="Mycie na budowie"
              value={userData.wash ? 1 : 0}
              labelId="wash-label"
              onChange={(e) => onChange('wash')(Boolean(e.target.value))}
            >
              <MenuItem value={1}>Tak</MenuItem>
              <MenuItem value={0}>Nie</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Rurociąg (stal/guma) ilość mb."
            type="number"
            value={userData.pipelineAmount ?? ''}
            onChange={(e) => onChange('pipelineAmount')(e.target.value)}
            error={Boolean(errors?.pipelineAmount)}
            helperText={errors?.pipelineAmount}
            onFocus={() =>
              `${userData.pipelineAmount}` === '0' &&
              onChange('pipelineAmount')('')
            }
            onBlur={() =>
              !userData.pipelineAmount && onChange('pipelineAmount')('0')
            }
          />
          <TextField
            fullWidth
            label="Przestawienie pompy (ilość)"
            type="number"
            value={userData.deliveredPGAmount ?? ''}
            onChange={(e) => onChange('deliveredPGAmount')(e.target.value)}
            error={Boolean(errors?.deliveredPGAmount)}
            helperText={errors?.deliveredPGAmount}
            onFocus={() =>
              `${userData.deliveredPGAmount}` === '0' &&
              onChange('deliveredPGAmount')('')
            }
            onBlur={() =>
              !userData.deliveredPGAmount && onChange('deliveredPGAmount')('0')
            }
          />
        </Box>
        <Box display="flex" gap={3} width="100%">
          <DesktopTimePicker
            label="Czas przybycia"
            value={userData.arrivalTime}
            onChange={onChange('arrivalTime')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                helperText={errors?.arrivalTime}
                error={Boolean(errors?.arrivalTime)}
              />
            )}
          />
          <DesktopTimePicker
            label="Czas wyjazdu"
            value={userData.departureTime}
            onChange={onChange('departureTime')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                helperText={errors?.departureTime}
                error={Boolean(errors?.departureTime)}
              />
            )}
          />
        </Box>
        <Box display="flex" gap={3} width="100%">
          <TextField
            fullWidth
            label="Uwagi kierowcy"
            type="text"
            multiline
            value={userData.additionalDriverInformation}
            onChange={(e) =>
              onChange('additionalDriverInformation')(e.target.value)
            }
          />
        </Box>
        <Box display="flex" gap={3} width="100%">
          <TextField
            fullWidth
            label="Uwagi kierownika budowy"
            type="text"
            multiline
            value={userData.additionalSupervisorInformation}
            onChange={(e) =>
              onChange('additionalSupervisorInformation')(e.target.value)
            }
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={3}
          p={1}
        >
          <Typography
            variant="subtitle2"
            color={errors?.pumpConfirmation ? 'error' : 'textSecondary'}
          >
            Czytelny podpis klienta
          </Typography>
          {userData.pumpConfirmation && (
            <Box sx={{ maxWidth: '300px' }}>
              <img
                src={userData.pumpConfirmation}
                alt="Podpis klienta"
                width="100%"
              />
            </Box>
          )}
          <Button variant="contained" onClick={() => setSignatureOpen(true)}>
            {userData.pumpConfirmation ? 'Podpisz ponownie' : 'Podpisz'}
          </Button>
        </Box>
      </Box>
    </>
  )
}
