import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { User } from '../client/user/user'
import { useService } from '../client/useService'
import { FeedbackSnackbarContext } from '../components/FeedbackSnackbar'
import { makeAuthService } from './authService'

interface Props {
  authenticate: (user: User) => void
}

export const LoginPage: React.FC<Props> = ({ authenticate }) => {
  const companyName = process.env.REACT_APP_COMPANY_NAME
  const [credentials, setCredentials] = useState({ username: '', password: '' })
  const authService = useService(makeAuthService)

  const snackbarContext = useContext(FeedbackSnackbarContext)

  const onChange = (field: 'username' | 'password') => (value: string) =>
    setCredentials({ ...credentials, [field]: value })

  const login = () =>
    authService
      .login(credentials.username, credentials.password)
      .then((user) => authenticate(user))
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Niepoprawny login lub hasło.',
          severity: 'error',
        })
      })

  return (
    <div>
      <Container maxWidth="sm">
        <Box margin={4}>
          <Typography variant="h4">{`Portal pracowników ${companyName}`}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <TextField
            fullWidth
            label="Nazwa użytkownika"
            value={credentials.username}
            onChange={(e) => onChange('username')(e.target.value)}
          />
          <TextField
            fullWidth
            label="Hasło"
            type="password"
            value={credentials.password}
            onChange={(e) => onChange('password')(e.target.value)}
          />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="large"
            onClick={login}
          >
            ZALOGUJ
          </Button>
        </Box>
      </Container>
    </div>
  )
}
