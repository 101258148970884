import { AxiosInstance } from 'axios'
import { Construction } from '.'
import { ConstructionGetQuery, ConstructionResult } from './construction'

const constructionsUrl = 'construction'

export const makeConstructionService = (api: AxiosInstance) => ({
  getConstructions: (
    query: ConstructionGetQuery,
  ): Promise<ConstructionResult> =>
    api
      .get(constructionsUrl, { params: query })
      .then((response) => response.data),
  deleteConstruction: (id: string): Promise<void> =>
    api.delete(`${constructionsUrl}/${id}`).then(() => {}),
  updateConstruction: (construction: Construction): Promise<void> =>
    api.patch(constructionsUrl, construction).then(() => {}),
  createConstruction: (construction: Construction): Promise<void> =>
    api.post(constructionsUrl, construction).then(() => {}),
})
