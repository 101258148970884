import { LinearProgress } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { makeConstructionService } from '../../../client/construction'
import { Form, makeFormService, PopulatedForm } from '../../../client/form'
import { makeOrdererService } from '../../../client/orderer'
import { makeUserService } from '../../../client/user'
import { useService } from '../../../client/useService'
import { FeedbackSnackbarContext } from '../../../components/FeedbackSnackbar'
import { FormCreation } from './FormCreation'

interface Props {
  isNew: boolean
}

export const FormContainer: React.FC<Props> = ({ isNew }) => {
  const params = useParams<{ id: string }>()
  const history = useHistory()

  const snackbarContext = useContext(FeedbackSnackbarContext)

  const formsService = useService(makeFormService)
  const userService = useService(makeUserService)
  const constructionService = useService(makeConstructionService)
  const ordererService = useService(makeOrdererService)

  const [prevForm, setPrevForm] = useState<PopulatedForm | null>()

  const getOrderers = (search: string) =>
    ordererService
      .getOrderers({ search, limit: 50, offset: 0 })
      .then((result) => _.uniq(result.items.map((item) => item.name)))
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować zamawiających',
          severity: 'error',
        })
        return Promise.reject(error)
      })

  const getConstructions = (search: string) =>
    constructionService
      .getConstructions({ search, limit: 50, offset: 0 })
      .then((result) => _.uniq(result.items.map((item) => item.name)))
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować budów',
          severity: 'error',
        })
        return Promise.reject(error)
      })

  const getDrivers = (search: string) =>
    userService
      .getUsers({ search, limit: 50, offset: 0 })
      .then((result) => result.items)
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować pracowników',
          severity: 'error',
        })
        return Promise.reject(error)
      })

  const navigateHome = () => history.push('/formularze')

  const submitForm = (form: Form) => {
    const action = isNew ? formsService.createForm : formsService.updateForm
    return action(form)
      .then(() => {
        history.push('/')
        snackbarContext.pushMessage({
          content: isNew
            ? `Pomyślnie utworzono formularz`
            : 'Pomyślnie zaktualizowano formularz',
          severity: 'success',
        })
      })
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: isNew
            ? `Nie udało się wysłać formularza`
            : `Nie udało się zaktualizować formularza`,
          severity: 'error',
        })
      })
  }

  useEffect(() => {
    if (isNew) return setPrevForm(null)
    formsService
      .getForm(params.id)
      .then(setPrevForm)
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować danych formularza',
          severity: 'error',
        })
        return Promise.reject(error)
      })
  }, [])

  if (prevForm === undefined) return <LinearProgress />
  return (
    <FormCreation
      getOrderers={getOrderers}
      getConstructions={getConstructions}
      getDrivers={getDrivers}
      submitForm={submitForm}
      prevForm={prevForm}
      navigateHome={navigateHome}
    />
  )
}
