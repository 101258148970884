import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
} from '@mui/material'
import React, { useState } from 'react'

interface Props {
  action: () => void
  title?: string
  content?: string
  color?: 'primary' | 'secondary'
}

export const ConfirmationWrapper: React.FC<Props> = ({
  action,
  title,
  content,
  children,
  color,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onAccept = () => {
    action()
    setIsOpen(false)
  }

  const defaultTitle = 'Czy jesteś pewien?'

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title ?? defaultTitle}</DialogTitle>
        {content && <DialogContent>{content}</DialogContent>}
        <DialogActions>
          <Box mr={'auto'}>
            <Button onClick={() => setIsOpen(false)} color="inherit">
              Anuluj
            </Button>
          </Box>
          <Button onClick={onAccept} color={color ?? 'error'}>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      <Box onClick={() => setIsOpen(true)}>{children}</Box>
    </>
  )
}
