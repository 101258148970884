import { LinearProgress } from '@mui/material'
import { AuthContext } from 'auth/AuthContext'
import { makeConstructionService } from 'client/construction'
import { Form, makeFormService } from 'client/form'
import {
  getEmptyPopulatedForm,
  PopulatedFormFromDb,
} from 'client/form/formPreparation'
import { makeOrdererService } from 'client/orderer'
import { useService } from 'client/useService'
import { FeedbackSnackbarContext } from 'components/FeedbackSnackbar'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FormStepper } from './FormStepper'

interface Props {
  isNew: boolean
}

export const FormContainer: React.FC<Props> = ({ isNew }) => {
  const params = useParams<{ id: string }>()
  const history = useHistory()

  const snackbarContext = useContext(FeedbackSnackbarContext)

  const userContext = useContext(AuthContext)
  const formsService = useService(makeFormService)

  const constructionService = useService(makeConstructionService)
  const ordererService = useService(makeOrdererService)

  const user = userContext.user!
  const [initialTemplate, setInitialTemplate] = useState<PopulatedFormFromDb>()

  const getOrderers = (search: string) =>
    ordererService
      .getOrderers({ search, limit: 50, offset: 0 })
      .then((result) => _.uniq(result.items.map((item) => item.name)))
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować zamawiających',
          severity: 'error',
        })
        return Promise.reject(error)
      })

  const getConstructions = (search: string) =>
    constructionService
      .getConstructions({ search, limit: 50, offset: 0 })
      .then((result) => _.uniq(result.items.map((item) => item.name)))
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować budów',
          severity: 'error',
        })
        return Promise.reject(error)
      })

  const submitForm = (form: Form) => {
    const action = isNew ? formsService.createForm : formsService.updateForm
    return action(form)
      .then(() => {
        history.push('/')
        snackbarContext.pushMessage({
          content: `Pomyślnie wysłano formularz`,
          severity: 'success',
        })
      })
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: `Nie udało się wysłać formularza`,
          severity: 'error',
        })
      })
  }

  useEffect(() => {
    if (isNew) return setInitialTemplate(getEmptyPopulatedForm(user))
    formsService
      .getForm(params.id)
      .then(setInitialTemplate)
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować danych formularza',
          severity: 'error',
        })
        return Promise.reject(error)
      })
  }, [])

  if (!initialTemplate) return <LinearProgress />
  return (
    <FormStepper
      initialTemplate={initialTemplate}
      getConstructions={getConstructions}
      getOrderers={getOrderers}
      submitForm={submitForm}
    />
  )
}
