import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

interface Props {
  getOptions: (search: string) => Promise<string[]>
  onChange: (value: string) => void
  label: string
  value: string
  error?: string
}

export const Autocomplete = ({
  getOptions,
  onChange,
  label,
  value,
  error,
}: Props) => {
  const [options, setOptions] = useState<string[] | undefined>()
  const [search, setSearch] = useState<string>('')

  const debouncedSetSearch = useCallback(_.debounce(setSearch, 500), [])

  useEffect(() => {
    if (options !== undefined) setOptions(undefined)

    let active = true
    getOptions(search).then((options) => {
      if (active) setOptions(options)
    })
    return () => {
      active = false
    }
  }, [search])

  return (
    <MuiAutocomplete
      fullWidth
      freeSolo
      loading={options === undefined}
      options={options ?? []}
      value={value}
      disableClearable
      loadingText="Ładowanie.."
      noOptionsText="Brak podpowiedzi."
      onChange={(e, option) => onChange(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          label={label}
          error={Boolean(error)}
          helperText={error}
          onChange={(e) => {
            setOptions(undefined)
            onChange(e.target.value)
            debouncedSetSearch(e.target.value)
          }}
        />
      )}
    />
  )
}
