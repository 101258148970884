import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  Pagination,
  Paper,
  Typography,
  TextField,
  DialogActions,
  useTheme,
} from '@mui/material'
import { nanoid } from 'nanoid'
import React, { useContext, useEffect, useState } from 'react'
import {
  makeOrdererService,
  Orderer,
  OrdererGetQuery,
  OrdererResult,
} from '../../../client/orderer'
import { useService } from '../../../client/useService'
import { ConfirmationWrapper } from '../../../components/ConfirmationWrapper'
import { FeedbackSnackbarContext } from '../../../components/FeedbackSnackbar'
import { LoadingBar } from '../../../components/LoadingBar'
import { SearchBar } from '../../../components/SearchBar'
interface Props {}

const perPage = 15

export const OrdererList: React.FC<Props> = (props) => {
  const theme = useTheme()
  const orderersService = useService(makeOrdererService)

  const snackbarContext = useContext(FeedbackSnackbarContext)

  const [ordererResult, setOrdererResult] = useState<OrdererResult>()
  const [loading, setLoading] = useState<boolean>(true)

  const [newOrdererName, setNewOrdererName] = useState<string>('')

  const [updatedOrderer, setUpdatedOrderer] = useState<Orderer | undefined>()

  const [query, setQuery] = useState<OrdererGetQuery>({
    search: '',
    limit: perPage,
    offset: 0,
    sort: undefined,
  })

  const onQueryChange = (field: keyof OrdererGetQuery) => (
    value: OrdererGetQuery[typeof field],
  ) => setQuery({ ...query, offset: 0, [field]: value })

  const loadOrderers = () =>
    orderersService
      .getOrderers(query)
      .then((result) => {
        setOrdererResult(result)
      })
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Coś poszło nie tak podczas ładowania zamawiających',
          severity: 'error',
        })
      })

  const deleteOrderer = async (id: string) => {
    setLoading(true)
    await orderersService
      .deleteOrderer(id)
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie usunięto zamawiającego',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się usunąć zamawiającego',
          severity: 'error',
        })
      })
    await loadOrderers()
    setLoading(false)
  }
  const createOrderer = async () => {
    setLoading(true)
    await orderersService
      .createOrderer({ name: newOrdererName, _id: nanoid() })
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie utworzono zamawiającego',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się utworzyć zamawiającego',
          severity: 'error',
        })
      })
    setNewOrdererName('')
    await loadOrderers()
    setLoading(false)
  }

  const updateOrderer = async () => {
    if (!updatedOrderer) return
    setLoading(true)
    await orderersService
      .updateOrderer(updatedOrderer)
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie zaktualizowano zamawiającego',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się zaktualizować zamawiającego',
          severity: 'error',
        })
      })
    setUpdatedOrderer(undefined)
    await loadOrderers()
    setLoading(false)
  }

  const closeUpdateDialog = () => setUpdatedOrderer(undefined)

  useEffect(() => {
    setLoading(true)
    loadOrderers().finally(() => setLoading(false))
  }, [query])

  return (
    <>
      <LoadingBar loading={loading} />
      <Dialog open={Boolean(updatedOrderer)} onClose={closeUpdateDialog}>
        {updatedOrderer && (
          <>
            <DialogTitle>Aktualizowanie zamawiającego</DialogTitle>
            <DialogContent>
              <Box pt={2}>
                <TextField
                  fullWidth
                  label="Nazwa"
                  value={updatedOrderer?.name}
                  onChange={(e) =>
                    setUpdatedOrderer({
                      ...updatedOrderer,
                      name: e.target.value,
                    })
                  }
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeUpdateDialog} color="inherit">
                Anuluj
              </Button>
              <Button
                color="primary"
                onClick={updateOrderer}
                disabled={loading}
              >
                Zapisz
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 2,
            },
          }}
        >
          <SearchBar
            initialValue={query.search}
            setValue={onQueryChange('search')}
          />
          <Box
            component={Paper}
            display="flex"
            alignItems="center"
            boxSizing="border-box"
            height="40px"
          >
            <Box px={2} py={1}>
              <InputBase
                value={newOrdererName}
                onChange={(e) => setNewOrdererName(e.target.value)}
              />
            </Box>
            <Button
              disabled={loading}
              sx={{
                height: '40px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
              variant="contained"
              onClick={createOrderer}
            >
              Stwórz
            </Button>
          </Box>
        </Box>
        {ordererResult && (
          <>
            <List>
              {ordererResult.items.map((orderer) => {
                return (
                  <React.Fragment key={orderer._id}>
                    <Divider />
                    <ListItem
                      sx={{ padding: 2 }}
                      secondaryAction={
                        <Box display="flex">
                          <IconButton
                            onClick={() => setUpdatedOrderer(orderer)}
                          >
                            <EditIcon />
                          </IconButton>
                          <ConfirmationWrapper
                            title="Na pewno usunąć zamawiającego?"
                            action={() => deleteOrderer(orderer._id)}
                          >
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </ConfirmationWrapper>
                        </Box>
                      }
                    >
                      {orderer.name}
                    </ListItem>
                  </React.Fragment>
                )
              })}
            </List>
            {ordererResult.items.length === 0 && (
              <Typography>Brak wyników</Typography>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
              {ordererResult.items.length > 0 && (
                <Pagination
                  count={Math.ceil(ordererResult.count / perPage)}
                  onChange={(e, page) =>
                    onQueryChange('offset')(perPage * (page - 1))
                  }
                  page={Math.floor(query.offset / perPage) + 1}
                  shape="rounded"
                />
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  )
}
