import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../auth/AuthContext'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import { useHistory } from 'react-router-dom'
import { useService } from '../client/useService'
import { makeAuthService } from '../auth/authService'
import { FeedbackSnackbarContext } from './FeedbackSnackbar'

interface Props {}

export const ApplicationBar: React.FC<Props> = (props) => {
  const history = useHistory()
  const companyName = process.env.REACT_APP_COMPANY_NAME
  const auth = useContext(AuthContext)
  const authService = useService(makeAuthService)
  const snackbarContext = useContext(FeedbackSnackbarContext)

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  const toggleDrawer = () => setDrawerOpen(!drawerOpen)

  const navigateToHome = () =>
    auth.user?.role === 'admin'
      ? history.push('/formularze')
      : history.push('/')

  const logOut = () =>
    authService
      .logout()
      .then(() => {
        setDrawerOpen(false)
        auth.setUser(undefined)
        history.push('/')
        snackbarContext.pushMessage({
          content: 'Pomyślnie wylogowano',
          severity: 'success',
        })
      })
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Coś poszło nie tak podczas wylogowywania',
          severity: 'error',
        })
      })

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {auth.isAuthenticated && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6">{`${companyName} Pracownik`}</Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor={'left'} open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem button onClick={navigateToHome}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Ekran główny</ListItemText>
          </ListItem>
          <ListItem button onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Wyloguj</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}
