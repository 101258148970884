import { nanoid } from 'nanoid'
import { User } from '../user'
import { WorkerFormInput } from './userFormTypes'

export interface PopulatedFormFromDb {
  _id: string
  initialData: {
    date: Date | null
    orderer: string
    construction: string
    driver: Pick<User, '_id' | 'name' | 'surname'>
    orderTime: Date
    registrationNumber: string
    pumpType: string
    orderedPump: string
  }
  userData: {
    hoursOfWork: number
    wash: boolean
    kilometersAmount: number
    pipelineAmount: number | undefined
    deliveredPGAmount: number | undefined
    arrivalTime: Date | null
    departureTime: Date
    cubicMetersAmount: number

    pumpConfirmation: string

    additionalDriverInformation: string
    additionalSupervisorInformation: string

    drive?: number
    concretingStart?: Date
    concretingEnd?: Date
  } | null
}

const getInitialAdminData = (user: User) => ({
  date: null,
  orderer: '',
  construction: '',
  driver: user,
  pumpType: '',
  registrationNumber: '',
  orderedPump: '',
  orderTime: new Date(),
})

export const getInitialUserData = () => ({
  hoursOfWork: 0,
  wash: false,
  kilometersAmount: 0,
  pipelineAmount: 0,
  deliveredPGAmount: 0,
  arrivalTime: null,
  departureTime: new Date(),
  cubicMetersAmount: 0,

  pumpConfirmation: '',

  additionalDriverInformation: '',
  additionalSupervisorInformation: '',
})

export const getEmptyPopulatedForm = (user: User): PopulatedFormFromDb => ({
  _id: nanoid(),
  initialData: getInitialAdminData(user),
  userData: getInitialUserData(),
})

export const parseFormFromLs = (form: WorkerFormInput) => ({
  ...form,
  initialData: {
    ...form.initialData,
    date: form.initialData.date ? new Date(form.initialData.date) : null,
    orderTime: new Date(form.initialData.orderTime),
  },
  userData: {
    ...form.userData,
    arrivalTime: form.userData.arrivalTime
      ? new Date(form.userData.arrivalTime)
      : null,
    departureTime: new Date(form.userData.departureTime),
  },
})

export const parsePopulatedFormFromDb = (
  form: PopulatedFormFromDb,
): WorkerFormInput => ({
  ...form,
  initialData: {
    ...form.initialData,
    driver: form.initialData.driver._id,
    date: form.initialData.date ? new Date(form.initialData.date) : null,
    orderTime: new Date(form.initialData.orderTime),
  },
  userData: form.userData
    ? {
        ...form.userData,
        arrivalTime: form.userData.arrivalTime
          ? new Date(form.userData.arrivalTime)
          : null,
        departureTime: new Date(form.userData.departureTime),
      }
    : getInitialUserData(),
})

// czy nowy formularz

// podaj nowy formularz z id i userem bez daty (PopulatedForm bez daty)
// lub
// podaj istniejacy formularz (PopulatedForm)

// czy formularz jest w ls
// nadpisz dane z poprzedniego formularza
// nic nie rob
