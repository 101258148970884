import { AxiosInstance } from 'axios'
import {
  Form,
  FormGetQuery,
  FormResult,
  FormSimple,
  PopulatedForm,
} from './form'

const formsUrl = 'form'

export const makeFormService = (api: AxiosInstance) => ({
  getForms: (query: FormGetQuery): Promise<FormResult> =>
    api.get(formsUrl, { params: query }).then((response) => ({
      count: response.data.count,
      items: response.data.items.map((form: FormSimple) => ({
        ...form,
        date: new Date(form.date),
      })),
    })),
  getForm: (id: string): Promise<PopulatedForm> =>
    api.get(`${formsUrl}/${id}`).then((response) => response.data),
  deleteForm: (id: string): Promise<void> =>
    api.delete(`${formsUrl}/${id}`).then(() => {}),
  updateForm: (form: Form): Promise<void> =>
    api.patch(formsUrl, form).then(() => {}),
  createForm: (form: Form): Promise<void> =>
    api.post(formsUrl, form).then(() => {}),
})
