import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { NewUserInput } from '../../../client/user'

interface Props {
  open: boolean
  closeDialog: () => void
  loading: boolean
  createUser: (user: NewUserInput) => void
}

const initialUser = () => ({
  _id: nanoid(),
  username: '',
  role: 'user' as const,
  name: '',
  surname: '',
  additional: '',
  password: '',
})

export const NewUserDialog: React.FC<Props> = ({
  open,
  closeDialog,
  loading,
  createUser,
}) => {
  const [newUser, setNewUser] = useState<NewUserInput>(initialUser())

  const onChange = (field: keyof NewUserInput) => (
    value: NewUserInput[typeof field],
  ) => {
    const prevUser = newUser ?? initialUser()
    setNewUser(
      newUser
        ? { ...prevUser, [field]: value }
        : { ...prevUser, [field]: value },
    )
  }

  useEffect(() => {
    setNewUser(initialUser())
  }, [open])

  useEffect(() => {
    if (!newUser) return
    onChange('username')(
      `${newUser?.name ? newUser?.name.charAt(0).toLowerCase() : ''}${
        newUser?.surname ? newUser.surname.toLowerCase() : ''
      }`,
    )
  }, [newUser?.name, newUser?.surname])

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth>
      {newUser && (
        <>
          <DialogTitle>Nowy użytkownik</DialogTitle>
          <DialogContent>
            <Box
              pt={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={2}
            >
              <TextField
                fullWidth
                label="Imię"
                value={newUser.name}
                onChange={(e) => onChange('name')(e.target.value)}
              />
              <TextField
                fullWidth
                label="Nazwisko"
                value={newUser.surname}
                onChange={(e) => onChange('surname')(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Rola</InputLabel>
                <Select
                  onChange={(e) => onChange('role')(e.target.value)}
                  label="Rola"
                  value={newUser.role}
                  labelId="role-select-label"
                >
                  <MenuItem value="admin">Administrator</MenuItem>
                  <MenuItem value="user">Użytkownik</MenuItem>
                </Select>
              </FormControl>
              <TextField
                disabled
                fullWidth
                label="Nazwa użytkownika"
                value={newUser.username}
              />

              <TextField
                fullWidth
                label="Dodatkowe informacje"
                multiline
                value={newUser.additional}
                onChange={(e) => onChange('additional')(e.target.value)}
              />
              <TextField
                fullWidth
                label="Hasło"
                value={newUser.password}
                onChange={(e) => onChange('password')(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="inherit">
              Anuluj
            </Button>
            <Button
              color="primary"
              onClick={() => createUser(newUser)}
              disabled={loading}
            >
              Zapisz
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
