import SearchIcon from '@mui/icons-material/Search'
import { Box, InputBase, Paper } from '@mui/material'
import _ from 'lodash'
import React, { useCallback } from 'react'

interface Props {
  initialValue: string
  setValue: (value: string) => void
}

export const SearchBar: React.FC<Props> = ({ initialValue, setValue }) => {
  const debouncedChange = useCallback(_.debounce(setValue, 300), [])
  return (
    <Box
      component={Paper}
      px={2}
      py={1}
      boxSizing="border-box"
      height="40px"
      gap={2}
      maxWidth="250px"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <SearchIcon />
      <InputBase
        fullWidth
        onChange={(e) => debouncedChange(e.target.value)}
        defaultValue={initialValue}
      />
    </Box>
  )
}
