import { Button, Dialog, DialogActions } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

interface Props {
  open: boolean
  close: () => void
  setSignature: (signature: string) => void
}

export const SignatureInput: React.FC<Props> = ({
  open,
  close,
  setSignature,
}) => {
  const [signatureUrl, setSignatureUrl] = useState<string>('')
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  const canvasRef = useRef<SignatureCanvas>(null)

  const onSave = () => {
    if (!canvasRef.current) return
    const img = canvasRef.current.getTrimmedCanvas().toDataURL('image/png')
    setSignature(img)
    close()
  }

  const clear = () => {
    if (!canvasRef.current) return
    canvasRef.current.clear()
  }

  useEffect(() => {
    const handleResize = () => {
      if (!canvasRef.current) return
      setSignatureUrl(canvasRef.current.toDataURL('image/png'))
      setDimensions({ height: window.innerHeight, width: window.innerWidth })
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize ', handleResize)
  }, [])

  useEffect(() => {
    if (!canvasRef.current || !signatureUrl) return
    canvasRef.current.fromDataURL(signatureUrl)
  }, [signatureUrl])

  return (
    <Dialog open={open} maxWidth={false}>
      <SignatureCanvas
        ref={canvasRef}
        clearOnResize={false}
        canvasProps={{
          height: dimensions.height - 200,
          width: dimensions.width - 200,
        }}
      />
      <DialogActions>
        <Button onClick={close}>Zamknij</Button>
        <Button onClick={clear}>Wyczyść</Button>
        <Button variant="contained" onClick={onSave}>
          Zapisz podpis
        </Button>
      </DialogActions>
    </Dialog>
  )
}
