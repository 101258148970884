import { Autocomplete, TextField } from '@mui/material'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

interface Props<T, V> {
  getOptions: (search: string) => Promise<T[]>
  getLabel: (option: T) => string
  getKey: (option: T) => string
  getValue: (option: T) => V
  value: V | null
  onChange: (value: V | null) => void
  defaultOption?: T
  label: string
  error?: string
  size?: 'small' | 'medium'
}

export const Select = <T, V>({
  getOptions,
  getLabel,
  getKey,
  getValue,
  onChange,
  defaultOption,
  label,
  size,
  error,
}: Props<T, V>) => {
  const [options, setOptions] = useState<T[] | undefined>()
  const [search, setSearch] = useState<string>(
    defaultOption ? getLabel(defaultOption) : '',
  )

  const [selectedOption, setSelectedOption] = useState<T | null>(
    defaultOption ?? null,
  )

  const debouncedSetSearch = useCallback(_.debounce(setSearch, 500), [])

  const onBlur = () => {
    if (!selectedOption) return setSearch('')
    setSearch(getLabel(selectedOption))
  }

  useEffect(() => {
    if (options !== undefined) setOptions(undefined)

    let active = true
    getOptions(search).then((options) => {
      if (active) setOptions(options)
    })
    return () => {
      active = false
    }
  }, [search])

  useEffect(() => {
    onChange(selectedOption ? getValue(selectedOption) : null)
    setSearch(selectedOption ? getLabel(selectedOption) : '')
  }, [selectedOption])

  const getOptionsToPass = () => {
    if (options === undefined) return []
    const selected = selectedOption ? [selectedOption] : []
    return _.uniqWith(
      [...options, ...selected],
      (a, b) => getKey(a) === getKey(b),
    )
  }

  return (
    <Autocomplete
      fullWidth
      loading={options === undefined}
      options={getOptionsToPass()}
      loadingText="Ładowanie.."
      noOptionsText="Brak opcji."
      getOptionLabel={getLabel}
      onBlur={onBlur}
      onChange={(e, option) => setSelectedOption(option)}
      value={selectedOption}
      isOptionEqualToValue={(option, value) => getKey(option) === getKey(value)}
      renderOption={(props, option) => (
        <li {...props} key={getKey(option)}>
          {getLabel(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size={size ?? 'medium'}
          error={Boolean(error)}
          helperText={error}
          onChange={(e) => {
            setOptions(undefined)
            debouncedSetSearch(e.target.value)
          }}
        />
      )}
    />
  )
}
