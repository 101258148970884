import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { UpdateUserInput, User } from '../../../client/user'

interface Props {
  currentUser: User | undefined
  closeDialog: () => void
  loading: boolean
  updateUser: (user: UpdateUserInput) => void
}

export const UpdateUserDialog: React.FC<Props> = ({
  currentUser,
  closeDialog,
  loading,
  updateUser,
}) => {
  const [updatedUser, setUpdatedUser] = useState<UpdateUserInput>()

  const onChange = (field: keyof UpdateUserInput) => (
    value: UpdateUserInput[typeof field],
  ) => {
    if (!updatedUser) return
    setUpdatedUser({ ...updatedUser, [field]: value })
  }

  useEffect(() => {
    setUpdatedUser(currentUser)
  }, [currentUser])

  useEffect(() => {
    if (!updatedUser) return
    onChange('username')(
      `${updatedUser?.name ? updatedUser?.name.charAt(0).toLowerCase() : ''}${
        updatedUser?.surname ? updatedUser.surname.toLowerCase() : ''
      }`,
    )
  }, [updatedUser?.name, updatedUser?.surname])

  return (
    <Dialog
      open={Boolean(currentUser)}
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth
    >
      {updatedUser && (
        <>
          <DialogTitle>Aktualizowanie użytkownika</DialogTitle>
          <DialogContent>
            <Box
              pt={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={2}
            >
              <TextField
                fullWidth
                label="Imię"
                value={updatedUser.name}
                onChange={(e) => onChange('name')(e.target.value)}
              />
              <TextField
                fullWidth
                label="Nazwisko"
                value={updatedUser.surname}
                onChange={(e) => onChange('surname')(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Rola</InputLabel>
                <Select
                  onChange={(e) => onChange('role')(e.target.value)}
                  label="Rola"
                  value={updatedUser.role}
                  labelId="role-select-label"
                >
                  <MenuItem value="admin">Administrator</MenuItem>
                  <MenuItem value="user">Użytkownik</MenuItem>
                </Select>
              </FormControl>
              <TextField
                disabled
                fullWidth
                label="Nazwa użytkownika"
                value={updatedUser.username}
              />
              <TextField
                fullWidth
                label="Dodatkowe informacje"
                multiline
                value={updatedUser.additional}
                onChange={(e) => onChange('additional')(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Zmień hasło"
                onChange={(e, checked) =>
                  onChange('password')(checked ? '' : undefined)
                }
              />
              {updatedUser.password !== undefined && (
                <TextField
                  fullWidth
                  label="Hasło"
                  value={updatedUser.password}
                  onChange={(e) => onChange('password')(e.target.value)}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="inherit">
              Anuluj
            </Button>
            <Button
              color="primary"
              onClick={() => updateUser(updatedUser)}
              disabled={loading}
            >
              Zapisz
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
