import { AxiosInstance } from 'axios'
import { User } from './user'
import { UserGetQuery, UserResult } from './user'

const usersUrl = 'user'

export const makeUserService = (api: AxiosInstance) => ({
  getUsers: (query: UserGetQuery): Promise<UserResult> =>
    api.get(usersUrl, { params: query }).then((response) => response.data),
  deleteUser: (id: string): Promise<void> =>
    api.delete(`${usersUrl}/${id}`).then(() => {}),
  updateUser: (user: User): Promise<void> =>
    api.patch(usersUrl, user).then(() => {}),
  createUser: (user: User): Promise<void> =>
    api.post(usersUrl, user).then(() => {}),
})
