import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Paper,
  Typography,
  useTheme,
  Select as MuiSelect,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FormGetQuery, FormResult, FormSortFields, makeFormService } from '../../../client/form';
import { makeUserService } from '../../../client/user';
import { useService } from '../../../client/useService';
import { ConfirmationWrapper } from '../../../components/ConfirmationWrapper';
import { FeedbackSnackbarContext } from '../../../components/FeedbackSnackbar';
import { ListItemHeader } from '../../../components/ListItemHeader';
import { LoadingBar } from '../../../components/LoadingBar';
import { SearchBar } from '../../../components/SearchBar';
import { Select } from '../../../components/Select';
import { CsvDownloadDialog } from './CsvDownloadDialog';

interface Props {}

const perPage = 15;

export const FormList: React.FC<Props> = (props) => {
  const theme = useTheme();
  const history = useHistory();

  const snackbarContext = useContext(FeedbackSnackbarContext);

  const formsService = useService(makeFormService);
  const userService = useService(makeUserService);

  const [formResult, setFormResult] = useState<FormResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [csvDialogOpen, setCsvDialogOpen] = useState<boolean>(false);

  const [query, setQuery] = useState<FormGetQuery>({
    search: '',
    limit: perPage,
    offset: 0,
    sort: { field: 'date', order: 'desc' }
  });

  const onQueryChange = (field: keyof FormGetQuery) => (value: FormGetQuery[typeof field]) =>
    setQuery({ ...query, offset: 0, [field]: value });

  const loadForms = () =>
    formsService
      .getForms(query)
      .then((result) => {
        setFormResult(result);
      })
      .catch((error) => {
        console.log(error);
        snackbarContext.pushMessage({
          content: 'Coś poszło nie tak podczas ładowania formularzy',
          severity: 'error'
        });
      });

  const deleteForm = async (id: string) => {
    setLoading(true);
    await formsService
      .deleteForm(id)
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie usunięto formularz',
          severity: 'success'
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        snackbarContext.pushMessage({
          content: 'Coś poszło nie tak podczas ładowania formularzy',
          severity: 'error'
        });
      });
    await loadForms();
    setLoading(false);
  };

  const getDrivers = (search: string) =>
    userService
      .getUsers({ search, limit: 50, offset: 0 })
      .then((result) => result.items)
      .catch((error) => {
        console.log(error);
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować pracowników',
          severity: 'error'
        });
        return Promise.reject(error);
      });

  useEffect(() => {
    setLoading(true);
    loadForms().finally(() => setLoading(false));
  }, [query]);

  return (
    <>
      <LoadingBar loading={loading} />
      <CsvDownloadDialog open={csvDialogOpen} close={() => setCsvDialogOpen(false)} />
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              alignItems: 'flex-end'
            }
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={4}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 2
              }
            }}
          >
            <SearchBar initialValue={query.search} setValue={onQueryChange('search')} />
            <Box width="250px" component={Paper}>
              <Select
                size="small"
                getOptions={getDrivers}
                value={query.driverId}
                getValue={(o) => o._id}
                getLabel={(o) => `${o.name} ${o.surname}`}
                getKey={(o) => o._id}
                label="Zadania użytkownika:"
                onChange={(value) => onQueryChange('driverId')(value ?? undefined)}
              />
            </Box>
            <Box width="250px" component={Paper}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <MuiSelect
                  value={`${query.confirmed}`}
                  onChange={(e) =>
                    onQueryChange('confirmed')(
                      e.target.value !== 'undefined' ? JSON.parse(e.target.value as string) : undefined
                    )
                  }
                  label="Status"
                >
                  <MenuItem value={'true'}>Potwierdzone</MenuItem>
                  <MenuItem value={'false'}>Niepotwierdzone</MenuItem>
                  <MenuItem value={'undefined'}>Wszystkie</MenuItem>
                </MuiSelect>
              </FormControl>
            </Box>
          </Box>
          <Button variant="contained" onClick={() => history.push('/formularze/nowy')}>
            Utwórz
          </Button>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center" py={2} gap={2}>
          <Button variant="contained" onClick={() => setCsvDialogOpen(true)}>
            POBIERZ CSV
          </Button>
        </Box>
        {formResult && (
          <>
            <Box sx={{ overflow: 'auto' }}>
              <List sx={{ minWidth: '900px', width: '100%' }}>
                <Divider />
                <ListItem>
                  <ListItemHeader
                    width="30%"
                    label="Zamawiający/Budowa"
                    sort={{
                      value: query.sort,
                      field: 'orderer' as FormSortFields,
                      setSort: (sort) => setQuery({ ...query, sort })
                    }}
                  />
                  <ListItemHeader width="20%" label="Kierowca" />
                  <ListItemHeader
                    width="80px"
                    label={'Data'}
                    sort={{
                      value: query.sort,
                      field: 'date' as FormSortFields,
                      setSort: (sort) => setQuery({ ...query, sort })
                    }}
                  />
                  <ListItemHeader width="20%" label={'Potwierdzone'} />
                </ListItem>
                {formResult.items.map((form) => {
                  return (
                    <React.Fragment key={form._id}>
                      <Divider />
                      <ListItem
                        sx={{ padding: '8px 16px 8px 16px' }}
                        secondaryAction={
                          <Box display="flex">
                            {form.isConfirmed && (
                              <IconButton
                                download
                                href={process.env.REACT_APP_SERVER_ADDRESS + 'downloads/' + form._id}
                              >
                                <FileDownloadIcon />
                              </IconButton>
                            )}
                            <IconButton onClick={() => history.push('formularze/' + form._id)}>
                              <EditIcon />
                            </IconButton>
                            <ConfirmationWrapper title="Na pewno usunąć formularz?" action={() => deleteForm(form._id)}>
                              <IconButton>
                                <DeleteIcon />
                              </IconButton>
                            </ConfirmationWrapper>
                          </Box>
                        }
                      >
                        <ListItemText sx={{ width: '30%' }}>
                          <Box>
                            <Typography>{form.orderer}</Typography>
                            <Typography
                              color="textSecondary"
                              variant="caption"
                              display="block"
                              sx={{ marginTop: '-2px' }}
                            >
                              {form.construction}
                            </Typography>
                          </Box>
                        </ListItemText>
                        <ListItemText
                          sx={{ width: '20%' }}
                        >{`${form.driver.name} ${form.driver.surname}`}</ListItemText>
                        <ListItemText sx={{ width: '80px' }}>{form.date.toLocaleDateString()}</ListItemText>
                        <ListItemText sx={{ width: '20%' }}>
                          {form.isConfirmed ? <AssignmentTurnedInIcon sx={{ marginLeft: 5.5 }} /> : ''}
                        </ListItemText>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </List>
            </Box>
            {formResult.items.length === 0 && <Typography>Brak wyników</Typography>}
            <Box display="flex" justifyContent="center" my={2}>
              {formResult.items.length > 0 && (
                <Pagination
                  count={Math.ceil(formResult.count / perPage)}
                  onChange={(e, page) => onQueryChange('offset')(perPage * (page - 1))}
                  page={Math.floor(query.offset / perPage) + 1}
                  shape="rounded"
                />
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  );
};
