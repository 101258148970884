import { DesktopDatePicker, DesktopTimePicker } from '@mui/lab'
import { Box, Button, Switch, TextField, Typography } from '@mui/material'
import { WorkerFormInitialDataInput } from 'client/form/userFormTypes'
import React, { useState } from 'react'
import { Autocomplete } from 'components/Autocomplete'

interface Props {
  initialData: WorkerFormInitialDataInput
  onChange: (
    field: keyof WorkerFormInitialDataInput,
  ) => (value: WorkerFormInitialDataInput[typeof field] | null) => void
  getOrderers: (search: string) => Promise<string[]>
  getConstructions: (search: string) => Promise<string[]>
  errors: {
    [key: string]: string
  }
  resetToInitial: () => void
}

export const InitialDataStep: React.FC<Props> = ({
  initialData,
  onChange,
  getOrderers,
  getConstructions,
  errors,
  resetToInitial,
}) => {
  const [manualDate, setManualDate] = useState<boolean>(
    Boolean(initialData.date),
  )

  const switchDateMode = (value: boolean) => {
    value ? onChange('date')(new Date()) : onChange('date')(null)
    setManualDate(value)
  }

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Button
          sx={{ display: 'block', marginRight: 'auto' }}
          onClick={resetToInitial}
        >
          Przywróć początkowe dane
        </Button>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2">
            {`Data - ${
              initialData.date
                ? initialData.date.toLocaleDateString()
                : `${new Date().toLocaleDateString()} (Automatyczna)`
            }`}
          </Typography>
          <Switch
            checked={manualDate}
            onChange={(e, value) => switchDateMode(value)}
          />
        </Box>
      </Box>
      <Box
        my={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={3}
      >
        {manualDate && (
          <Box display="flex" gap={3} width="100%">
            <DesktopDatePicker
              label="Data"
              value={initialData.date}
              onChange={onChange('date')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={Boolean(errors?.date)}
                  helperText={errors?.date}
                />
              )}
            />
          </Box>
        )}
        <Box display="flex" gap={3} width="100%">
          <DesktopTimePicker
            label="Czas dostawy"
            value={initialData.orderTime}
            onChange={onChange('orderTime')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={Boolean(errors?.orderTime)}
                helperText={errors?.orderTime}
              />
            )}
          />
        </Box>

        <Autocomplete
          value={initialData.orderer}
          getOptions={getOrderers}
          onChange={onChange('orderer')}
          label="Zamawiający"
          error={errors.orderer}
        />
        <Autocomplete
          value={initialData.construction}
          getOptions={getConstructions}
          onChange={onChange('construction')}
          label="Budowa"
          error={errors.construction}
        />
        <Box display="flex" gap={3} width="100%">
          <TextField
            fullWidth
            label="Typ pompy"
            value={initialData.pumpType}
            onChange={(e) => onChange('pumpType')(e.target.value)}
            error={Boolean(errors?.pumpType)}
            helperText={errors?.pumpType}
          />
          <TextField
            fullWidth
            label="Nr rej."
            value={initialData.registrationNumber}
            onChange={(e) => onChange('registrationNumber')(e.target.value)}
            error={Boolean(errors?.registrationNumber)}
            helperText={errors?.registrationNumber}
          />
          <TextField
            fullWidth
            label="Zamówiona pompa"
            value={initialData.orderedPump}
            onChange={(e) => onChange('orderedPump')(e.target.value)}
            error={Boolean(errors?.orderedPump)}
            helperText={errors?.orderedPump}
          />
        </Box>
      </Box>
    </>
  )
}
