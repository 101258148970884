import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { ThemeProvider } from '@mui/material'
import { pl } from 'date-fns/locale'
import React, { useContext } from 'react'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import { AuthContext, AuthProvider } from './auth/AuthContext'
import { AuthGuard } from './auth/AuthGuard'
import { ClientProvider } from './client/ClientContext'
import { ApplicationBar } from './components/ApplicationBar'
import { FeedbackSnackbarProvider } from './components/FeedbackSnackbar'
import { theme } from './theme'
import { AdminHomeContainer } from './views/admin/AdminHomeContainer'
import { FormContainer } from './views/admin/form/FormContainer'
import { UserHomeContainer } from './views/user/UserHomeContainer'

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
        <ThemeProvider theme={theme}>
          <FeedbackSnackbarProvider>
            <Router>
              <AuthProvider>
                <ClientProvider>
                  <ApplicationBar />

                  <AuthGuard>
                    <MainSwitch />
                  </AuthGuard>
                </ClientProvider>
              </AuthProvider>
            </Router>
          </FeedbackSnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}

const MainSwitch = () => {
  const auth = useContext(AuthContext)

  if (!auth.user?.role) return <h3>Unauthorized</h3>
  if (auth.user.role === 'admin')
    return (
      <Switch>
        <Route path="/formularze/nowy" exact>
          <FormContainer isNew={true} />
        </Route>
        <Route path="/formularze/:id" exact>
          <FormContainer isNew={false} />
        </Route>
        <Route path="/:tab" exact>
          <AdminHomeContainer />
        </Route>
        <Route path="/" exact>
          <Redirect to={'/formularze'} />
        </Route>
      </Switch>
    )
  return (
    <Route path="/">
      <UserHomeContainer />
    </Route>
  )
}

export default App
