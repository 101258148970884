import { Box, Button, Container, Step, StepLabel, Stepper } from '@mui/material'
import { get as lsGet, remove as lsRemove, set as lsSet } from 'local-storage'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Form, FormInitialData } from 'client/form'
import {
  parseFormFromLs,
  parsePopulatedFormFromDb,
  PopulatedFormFromDb,
} from 'client/form/formPreparation'
import {
  validateInitialData,
  validateUserData,
} from 'client/form/formValidator'
import { InitialDataStep } from './InitialDataStep'
import { UserDataStep } from './UserDataStep'
import {
  WorkerFormInput,
  WorkerFormUserDataInput,
} from 'client/form/userFormTypes'

interface Props {
  initialTemplate: PopulatedFormFromDb
  getOrderers: (search: string) => Promise<string[]>
  getConstructions: (search: string) => Promise<string[]>
  submitForm: (form: Form) => Promise<void>
}

export const FormStepper: React.FC<Props> = ({
  initialTemplate,
  getOrderers,
  getConstructions,
  submitForm,
}) => {
  const [step, setStep] = useState(0)

  const formFromLs = useMemo(
    () => lsGet<WorkerFormInput | null>(initialTemplate._id),
    [initialTemplate._id],
  )

  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const formToSet = useMemo(
    () =>
      formFromLs
        ? {
            ...parsePopulatedFormFromDb(initialTemplate),
            ...parseFormFromLs(formFromLs),
          }
        : parsePopulatedFormFromDb(initialTemplate),
    [],
  )
  const [form, setForm] = useState<WorkerFormInput>(formToSet)

  const onInitialDataChange = (field: keyof FormInitialData) => (
    value: FormInitialData[typeof field] | null,
  ) =>
    setForm({ ...form, initialData: { ...form.initialData, [field]: value } })

  const onUserDataChange = (field: keyof WorkerFormUserDataInput) => (
    value: WorkerFormUserDataInput[typeof field] | null,
  ) => setForm({ ...form, userData: { ...form.userData, [field]: value } })

  const resetToInitial = () => {
    setForm({
      ...parsePopulatedFormFromDb(initialTemplate),
      userData: form.userData,
    })
    lsRemove(form._id)
  }

  const moveToSecondStep = () => {
    const result = validateInitialData(form.initialData)
    if (result.error) return setErrors(result.error)
    if (!form.userData.arrivalTime)
      setForm({
        ...form,
        userData: { ...form.userData, arrivalTime: form.initialData.orderTime },
      })
    setErrors({})
    setStep(1)
  }

  const onSubmit = () => {
    const initialDataResult = validateInitialData(form.initialData)
    if (initialDataResult.error) return setErrors(initialDataResult.error)
    const userDataResult = validateUserData(form.userData)
    if (userDataResult.error) return setErrors(userDataResult.error)
    const formToSubmit = {
      initialData: {
        ...initialDataResult.value,
        date: initialDataResult.value.date ?? new Date(),
      },
      userData: userDataResult.value,
      _id: form._id,
    }
    setErrors({})
    submitForm(formToSubmit).then(() => {
      lsRemove(formToSubmit._id)
    })
  }

  useEffect(() => {
    if (_.isEqual(formToSet, form)) return
    if (form)
      lsSet<WorkerFormInput>(
        form._id,
        _.omit(form, 'userData.pumpConfirmation'),
      )
  }, [form])

  return (
    <Container sx={{ marginTop: 2 }}>
      <Stepper activeStep={step}>
        <Step key="initial">
          <StepLabel>Podstawowe dane</StepLabel>
        </Step>
        <Step key="user">
          <StepLabel>Dane pracownika</StepLabel>
        </Step>
      </Stepper>
      <Box mt={4}>
        {step === 0 && (
          <InitialDataStep
            onChange={onInitialDataChange}
            initialData={form.initialData}
            getOrderers={getOrderers}
            getConstructions={getConstructions}
            errors={errors}
            resetToInitial={resetToInitial}
          />
        )}
        {step === 1 && (
          <UserDataStep
            userData={form.userData}
            onChange={onUserDataChange}
            errors={errors}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          color="inherit"
          disabled={step === 0}
          onClick={() => setStep(0)}
        >
          Cofnij
        </Button>
        {step === 0 && (
          <Button onClick={moveToSecondStep} variant="contained">
            Dalej
          </Button>
        )}
        {step === 1 && (
          <Button onClick={onSubmit} variant="contained">
            Zakończ i wyślij
          </Button>
        )}
      </Box>
    </Container>
  )
}
