import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ConstructionList } from './construction'
import { FormList } from './form'
import { OrdererList } from './orderer'
import { UserList } from './user'

interface Props {}

type TabValue = 'formularze' | 'uzytkownicy' | 'zamawiajacy' | 'budowy'

export const AdminHomeContainer: React.FC<Props> = (props) => {
  const history = useHistory()
  const params = useParams<{ tab: TabValue }>()

  const switchTab = (tab: TabValue) => history.replace(tab)

  return (
    <>
      <Box>
        <Tabs onChange={(e, tab) => switchTab(tab)} value={params.tab}>
          <Tab label="Formularze" value="formularze" />
          <Tab label="Użytkownicy" value="uzytkownicy" />
          <Tab label="Zamawiający" value="zamawiajacy" />
          <Tab label="Budowy" value="budowy" />
        </Tabs>
      </Box>
      {params.tab === 'formularze' && <FormList />}
      {params.tab === 'uzytkownicy' && <UserList />}
      {params.tab === 'zamawiajacy' && <OrdererList />}
      {params.tab === 'budowy' && <ConstructionList />}
    </>
  )
}
