import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Pagination,
  Paper,
  Typography,
  Select as MuiSelect,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  FormGetQuery,
  FormResult,
  FormSortFields,
  makeFormService,
} from '../../client/form'
import { useService } from '../../client/useService'
import { FeedbackSnackbarContext } from '../../components/FeedbackSnackbar'
import { ListItemHeader } from '../../components/ListItemHeader'
import { LoadingBar } from '../../components/LoadingBar'
import { SearchBar } from '../../components/SearchBar'

interface Props {}

const perPage = 15

export const MyFormList: React.FC<Props> = (props) => {
  const history = useHistory()

  const snackbarContext = useContext(FeedbackSnackbarContext)

  const formsService = useService(makeFormService)

  const [formResult, setFormResult] = useState<FormResult>()
  const [loading, setLoading] = useState<boolean>(true)

  const [query, setQuery] = useState<FormGetQuery>({
    search: '',
    limit: perPage,
    offset: 0,
    confirmed: false,
    sort: { field: 'date', order: 'desc' },
  })

  const onQueryChange = (field: keyof FormGetQuery) => (
    value: FormGetQuery[typeof field],
  ) => setQuery({ ...query, offset: 0, [field]: value })

  const loadForms = () =>
    formsService
      .getForms(query)
      .then(setFormResult)
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Coś poszło nie tak podczas ładowania formularzy',
          severity: 'error',
        })
      })

  useEffect(() => {
    setLoading(true)
    loadForms().finally(() => setLoading(false))
  }, [query])

  return (
    <>
      <LoadingBar loading={loading} />
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={2}
        >
          <Box display="flex" alignItems="center" gap={4}>
            <SearchBar
              initialValue={query.search}
              setValue={onQueryChange('search')}
            />
            <Box width="250px" component={Paper}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <MuiSelect
                  value={`${query.confirmed}`}
                  onChange={(e) =>
                    onQueryChange('confirmed')(
                      e.target.value !== 'undefined'
                        ? JSON.parse(e.target.value as string)
                        : undefined,
                    )
                  }
                  label="Status"
                >
                  <MenuItem value={'true'}>Potwierdzone</MenuItem>
                  <MenuItem value={'false'}>Niepotwierdzone</MenuItem>
                  <MenuItem value={'undefined'}>Wszystkie</MenuItem>
                </MuiSelect>
              </FormControl>
            </Box>
          </Box>
          <Button variant="contained" onClick={() => history.push('/nowy')}>
            Utwórz
          </Button>
        </Box>
        {formResult && (
          <>
            <List>
              <Divider />
              <ListItem>
                <ListItemHeader
                  width="30%"
                  label="Zamawiający/Budowa"
                  sort={{
                    value: query.sort,
                    field: 'orderer' as FormSortFields,
                    setSort: (sort) => setQuery({ ...query, sort }),
                  }}
                />
                <ListItemHeader
                  width="20%"
                  label={'Data'}
                  sort={{
                    value: query.sort,
                    field: 'date' as FormSortFields,
                    setSort: (sort) => setQuery({ ...query, sort }),
                  }}
                />
                <ListItemHeader
                  width="20%"
                  label={'Potwierdzone'}
                  sort={{
                    value: query.sort,
                    field: 'pumpConfirmation' as FormSortFields,
                    setSort: (sort) => setQuery({ ...query, sort }),
                  }}
                />
              </ListItem>
              {formResult.items.map((form) => {
                return (
                  <React.Fragment key={form._id}>
                    <Divider />
                    <ListItem
                      button
                      sx={{ padding: '8px 16px 8px 16px' }}
                      onClick={() => history.push('/' + form._id)}
                    >
                      <ListItemText sx={{ width: '30%' }}>
                        <Box>
                          <Typography>{form.orderer}</Typography>
                          <Typography
                            color="textSecondary"
                            variant="caption"
                            display="block"
                            sx={{ marginTop: '-2px' }}
                          >
                            {form.construction}
                          </Typography>
                        </Box>
                      </ListItemText>

                      <ListItemText sx={{ width: '20%' }}>
                        {form.date.toLocaleDateString()}
                      </ListItemText>
                      <ListItemText sx={{ width: '20%' }}>
                        {form.isConfirmed ? (
                          <AssignmentTurnedInIcon sx={{ marginLeft: 5.5 }} />
                        ) : (
                          ''
                        )}
                      </ListItemText>
                    </ListItem>
                  </React.Fragment>
                )
              })}
            </List>
            {formResult.items.length === 0 && (
              <Typography>Brak zadań</Typography>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
              {formResult.items.length > 0 && (
                <Pagination
                  count={Math.ceil(formResult.count / perPage)}
                  onChange={(e, page) =>
                    onQueryChange('offset')(perPage * (page - 1))
                  }
                  page={Math.floor(query.offset / perPage) + 1}
                  shape="rounded"
                />
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  )
}
