import axios, { AxiosInstance } from 'axios'
import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../auth/AuthContext'

export const ClientContext = React.createContext<AxiosInstance>(axios)

export const ClientProvider: React.FC = (props) => {
  const auth = useContext(AuthContext)
  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  })

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        auth.setUser(undefined)
      }
      return Promise.reject(error)
    },
  )

  return (
    <ClientContext.Provider value={client}>
      {props.children}
    </ClientContext.Provider>
  )
}
