import { Alert, Snackbar } from '@mui/material'
import React, { useState } from 'react'

export interface Message {
  content: string
  severity: 'error' | 'warning' | 'info' | 'success'
}

export interface FeedbackContext {
  pushMessage: (msg: Message) => void
}

export const FeedbackSnackbarContext = React.createContext<FeedbackContext>({
  pushMessage: () => {
    return
  },
})

export const FeedbackSnackbarProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<Message>()

  const pushMessage = (msg: Message) => {
    setMessage(msg)
    setOpen(true)
  }

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') return
    setOpen(false)
  }

  return (
    <FeedbackSnackbarContext.Provider value={{ pushMessage }}>
      {message && (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={(event, reason) => handleClose(reason)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            variant="filled"
            onClose={() => handleClose()}
            severity={message.severity}
          >
            {message.content}
          </Alert>
        </Snackbar>
      )}

      {children}
    </FeedbackSnackbarContext.Provider>
  )
}
