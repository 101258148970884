import { Box, LinearProgress } from '@mui/material'
import React from 'react'

interface Props {
  loading: boolean
}

export const LoadingBar: React.FC<Props> = ({ loading }) => {
  return <Box height="4px">{loading && <LinearProgress />}</Box>
}
