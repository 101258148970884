import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Button, ListItemText, Typography, Box } from '@mui/material'
import React from 'react'

type Sort<T> =
  | {
      field: T
      order: 'desc' | 'asc'
    }
  | undefined

interface Props<T> {
  width: string
  sort?: {
    field: T
    value: Sort<T>
    setSort: (sort: Sort<T>) => void
  }
  label: string
}

export const ListItemHeader = <T,>({ width, sort, label }: Props<T>) => {
  const onClick = () => {
    if (!sort) return
    if (sort.value?.field !== sort.field)
      return sort.setSort({ field: sort.field, order: 'asc' })
    if (sort.value?.order === 'asc')
      return sort.setSort({ field: sort.field, order: 'desc' })
    sort.setSort(undefined)
  }

  const showButton = sort ? sort.value?.field === sort.field : false
  return (
    <ListItemText sx={{ width }}>
      <Button fullWidth onClick={onClick} color="inherit">
        <Box
          sx={{ width: '100%' }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography
            variant="button"
            textAlign="left"
            sx={{ marginRight: '4px' }}
          >
            {label}
          </Typography>
          {sort?.value?.order === 'asc' && showButton && <ArrowUpwardIcon />}
          {sort?.value?.order === 'desc' && showButton && <ArrowDownwardIcon />}
        </Box>
      </Button>
    </ListItemText>
  )
}
