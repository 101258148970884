import { DesktopDatePicker } from '@mui/lab';
import { Box, Button, Dialog, TextField } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import React from 'react';

interface Props {
  open: boolean;
  close: () => void;
}

export const CsvDownloadDialog: React.FC<Props> = (props) => {
  const [before, setBefore] = React.useState<Date | null>(null);
  const [after, setAfter] = React.useState<Date | null>(null);

  console.log(before?.toISOString());
  return (
    <Dialog open={props.open} onClose={props.close}>
      <Box p={4} display="flex" flexDirection="column" sx={{ gap: 1 }}>
        <DesktopDatePicker
          label="Od"
          value={after}
          onChange={(e) => setAfter(e)}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <DesktopDatePicker
          label="Do"
          value={before}
          onChange={(e) => setBefore(e)}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />

        <Button
          download
          variant="contained"
          disabled={!before || !after}
          href={
            after && before
              ? process.env.REACT_APP_SERVER_ADDRESS +
                `csv/${startOfDay(after).toISOString()}/${endOfDay(before).toISOString()}/`
              : ''
          }
          sx={{ width: '100%' }}
        >
          Pobierz
        </Button>
      </Box>
    </Dialog>
  );
};
