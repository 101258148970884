import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import {
  makeUserService,
  NewUserInput,
  UpdateUserInput,
  User,
  UserGetQuery,
  UserResult,
  UserSortFields,
} from '../../../client/user'
import { useService } from '../../../client/useService'
import { FeedbackSnackbarContext } from '../../../components/FeedbackSnackbar'
import { ListItemHeader } from '../../../components/ListItemHeader'
import { LoadingBar } from '../../../components/LoadingBar'
import { SearchBar } from '../../../components/SearchBar'
import { NewUserDialog } from './NewUserDialog'
import { UpdateUserDialog } from './UpdateUserDialog'

interface Props {}

const perPage = 15

const roleToTextMap = {
  user: 'Pracownik',
  admin: 'Administrator',
}

export const UserList: React.FC<Props> = (props) => {
  const usersService = useService(makeUserService)

  const snackbarContext = useContext(FeedbackSnackbarContext)

  const [userResult, setUserResult] = useState<UserResult>()
  const [loading, setLoading] = useState<boolean>(true)

  const [updateUserDialogData, setUpdateUserDialogData] = useState<
    User | undefined
  >()
  const [newUserDialog, setNewUserDialog] = useState<boolean>(false)

  const [query, setQuery] = useState<UserGetQuery>({
    search: '',
    limit: perPage,
    offset: 0,
    sort: undefined,
  })

  const onQueryChange = (field: keyof UserGetQuery) => (
    value: UserGetQuery[typeof field],
  ) => setQuery({ ...query, offset: 0, [field]: value })

  const loadUsers = () =>
    usersService
      .getUsers(query)
      .then(setUserResult)
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Coś poszło nie tak podczas ładowania użytkowników',
          severity: 'error',
        })
      })

  const createUser = async (newUser: NewUserInput) => {
    setLoading(true)
    await usersService
      .createUser(newUser)
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie utworzono użytkownika',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się utworzyć użytkownika',
          severity: 'success',
        })
      })
    setNewUserDialog(false)
    await loadUsers()
    setLoading(false)
  }

  const updateUser = async (updatedUser: UpdateUserInput) => {
    setLoading(true)
    await usersService
      .updateUser(updatedUser)
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie zaktualizowano użytkownika',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się zaktualizować użytkownika',
          severity: 'error',
        })
      })
    setUpdateUserDialogData(undefined)
    await loadUsers()
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    loadUsers().finally(() => setLoading(false))
  }, [query])

  return (
    <>
      <LoadingBar loading={loading} />
      <UpdateUserDialog
        currentUser={updateUserDialogData}
        closeDialog={() => setUpdateUserDialogData(undefined)}
        updateUser={updateUser}
        loading={loading}
      />
      <NewUserDialog
        open={newUserDialog}
        closeDialog={() => setNewUserDialog(false)}
        loading={loading}
        createUser={createUser}
      />
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={2}
        >
          <SearchBar
            initialValue={query.search}
            setValue={onQueryChange('search')}
          />
          <Button variant="contained" onClick={() => setNewUserDialog(true)}>
            Utwórz
          </Button>
        </Box>
        {userResult && (
          <>
            <Box sx={{ overflow: 'auto' }}>
              <List sx={{ minWidth: '600px', width: '100%' }}>
                <Divider />
                <ListItem>
                  <ListItemHeader
                    width="35%"
                    label="Imię Nazwisko"
                    sort={{
                      value: query.sort,
                      field: 'surname' as UserSortFields,
                      setSort: (sort) => setQuery({ ...query, sort }),
                    }}
                  />
                  <ListItemHeader
                    width="30%"
                    label="Nazwa użytkownika"
                    sort={{
                      value: query.sort,
                      field: 'username' as UserSortFields,
                      setSort: (sort) => setQuery({ ...query, sort }),
                    }}
                  />
                  <ListItemHeader
                    width="25%"
                    label={'Role'}
                    sort={{
                      value: query.sort,
                      field: 'role' as UserSortFields,
                      setSort: (sort) => setQuery({ ...query, sort }),
                    }}
                  />
                </ListItem>
                {userResult.items.map((user) => {
                  return (
                    <React.Fragment key={user._id}>
                      <Divider />
                      <ListItem
                        sx={{ padding: 2 }}
                        secondaryAction={
                          <Box>
                            <IconButton
                              onClick={() => setUpdateUserDialogData(user)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                        }
                      >
                        <ListItemText
                          sx={{ width: '35%' }}
                        >{`${user.name} ${user.surname}`}</ListItemText>
                        <ListItemText sx={{ width: '30%' }}>
                          {user.username}
                        </ListItemText>
                        <ListItemText sx={{ width: '25%' }}>
                          {roleToTextMap[user.role] ?? 'Nieznana'}
                        </ListItemText>
                      </ListItem>
                    </React.Fragment>
                  )
                })}
              </List>
            </Box>
            {userResult.items.length === 0 && (
              <Typography>Brak wyników</Typography>
            )}
            <Box display="flex" justifyContent="center" my={2}>
              {userResult.items.length > 0 && (
                <Pagination
                  count={Math.ceil(userResult.count / perPage)}
                  onChange={(e, page) =>
                    onQueryChange('offset')(perPage * (page - 1))
                  }
                  page={Math.floor(query.offset / perPage) + 1}
                  shape="rounded"
                />
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  )
}
