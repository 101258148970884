import React, { useContext, useEffect, useState } from 'react'
import { User } from '../client/user/user'
import { useService } from '../client/useService'
import { AuthContext } from './AuthContext'
import { makeAuthService } from './authService'
import { LoginPage } from './LoginPage'

export const AuthGuard: React.FC = (props) => {
  const auth = useContext(AuthContext)
  const authService = useService(makeAuthService)
  const [loading, setLoading] = useState(true)

  const authenticate = (user: User) => auth.setUser(user)

  useEffect(() => {
    authService
      .me()
      .then((user) => {
        auth.setUser(user)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }, [])

  if (loading) return null
  if (!auth.isAuthenticated || !auth.user)
    return <LoginPage authenticate={authenticate} />
  return <>{props.children}</>
}
