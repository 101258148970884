import React, { useState } from 'react'
import { User } from '../client/user/user'

export const AuthContext = React.createContext<{
  isAuthenticated: boolean
  user: User | undefined
  setUser: (user: User | undefined) => void
}>({ user: undefined, isAuthenticated: false, setUser: () => {} })

export const AuthProvider: React.FC = (props) => {
  const [user, setUser] = useState<User | undefined>(undefined)

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated: Boolean(user), setUser }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
