import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { FormContainer } from './Form/FormContainer'
import { MyFormList } from './MyFormsList'

interface Props {}

export const UserHomeContainer: React.FC<Props> = (props) => {
  return (
    <Switch>
      <Route path="/" exact>
        <MyFormList />
      </Route>

      <Route path="/nowy" exact>
        <FormContainer isNew={true} />
      </Route>
      <Route path="/:id" exact>
        <FormContainer isNew={false} />
      </Route>
    </Switch>
  )
}
