import { AxiosInstance } from 'axios'
import { Orderer } from '.'
import { OrdererGetQuery, OrdererResult } from './orderer'

const orderersUrl = 'orderer'

export const makeOrdererService = (api: AxiosInstance) => ({
  getOrderers: (query: OrdererGetQuery): Promise<OrdererResult> =>
    api.get(orderersUrl, { params: query }).then((response) => response.data),
  deleteOrderer: (id: string): Promise<void> =>
    api.delete(`${orderersUrl}/${id}`).then(() => {}),
  updateOrderer: (orderer: Orderer): Promise<void> =>
    api.patch(orderersUrl, orderer).then(() => {}),
  createOrderer: (orderer: Orderer): Promise<void> =>
    api.post(orderersUrl, orderer).then(() => {}),
})
