import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  Pagination,
  Paper,
  Typography,
  TextField,
  DialogActions,
  useTheme,
} from '@mui/material'
import { nanoid } from 'nanoid'
import React, { useContext, useEffect, useState } from 'react'
import {
  makeConstructionService,
  Construction,
  ConstructionGetQuery,
  ConstructionResult,
} from '../../../client/construction'
import { useService } from '../../../client/useService'
import { ConfirmationWrapper } from '../../../components/ConfirmationWrapper'
import { FeedbackSnackbarContext } from '../../../components/FeedbackSnackbar'
import { LoadingBar } from '../../../components/LoadingBar'
import { SearchBar } from '../../../components/SearchBar'
interface Props {}

const perPage = 15

export const ConstructionList: React.FC<Props> = (props) => {
  const theme = useTheme()
  const constructionsService = useService(makeConstructionService)
  const snackbarContext = useContext(FeedbackSnackbarContext)

  const [constructionResult, setConstructionResult] = useState<
    ConstructionResult
  >()
  const [loading, setLoading] = useState<boolean>(true)

  const [newConstructionName, setNewConstructionName] = useState<string>('')

  const [updatedConstruction, setUpdatedConstruction] = useState<
    Construction | undefined
  >()

  const [query, setQuery] = useState<ConstructionGetQuery>({
    search: '',
    limit: perPage,
    offset: 0,
    sort: undefined,
  })

  const onQueryChange = (field: keyof ConstructionGetQuery) => (
    value: ConstructionGetQuery[typeof field],
  ) => setQuery({ ...query, offset: 0, [field]: value })

  const loadConstructions = () =>
    constructionsService
      .getConstructions(query)
      .then((result) => {
        setConstructionResult(result)
      })
      .catch((error) => {
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się załadować budów',
          severity: 'error',
        })
      })

  const deleteConstruction = async (id: string) => {
    setLoading(true)
    await constructionsService
      .deleteConstruction(id)
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie usunięto budowę',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się usunąć budowy',
          severity: 'error',
        })
      })
    await loadConstructions()
    setLoading(false)
  }

  const createConstruction = async () => {
    setLoading(true)
    await constructionsService
      .createConstruction({ name: newConstructionName, _id: nanoid() })
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie utworzono budowę',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się utworzyć budowy',
          severity: 'error',
        })
      })
    setNewConstructionName('')
    await loadConstructions()
    setLoading(false)
  }
  const updateConstruction = async () => {
    if (!updatedConstruction) return
    setLoading(true)
    await constructionsService
      .updateConstruction(updatedConstruction)
      .then(() => {
        snackbarContext.pushMessage({
          content: 'Pomyślnie zaktualizowano budowę',
          severity: 'success',
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        snackbarContext.pushMessage({
          content: 'Nie udało się zaktualizować budowy',
          severity: 'error',
        })
      })
    setUpdatedConstruction(undefined)
    await loadConstructions()
    setLoading(false)
  }

  const closeUpdateDialog = () => setUpdatedConstruction(undefined)

  useEffect(() => {
    setLoading(true)
    loadConstructions().finally(() => setLoading(false))
  }, [query])

  return (
    <>
      <LoadingBar loading={loading} />
      <Dialog open={Boolean(updatedConstruction)} onClose={closeUpdateDialog}>
        {updatedConstruction && (
          <>
            <DialogTitle>Aktualizowanie budowy</DialogTitle>
            <DialogContent>
              <Box pt={2}>
                <TextField
                  fullWidth
                  label="Nazwa"
                  value={updatedConstruction?.name}
                  onChange={(e) =>
                    setUpdatedConstruction({
                      ...updatedConstruction,
                      name: e.target.value,
                    })
                  }
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeUpdateDialog} color="inherit">
                Anuluj
              </Button>
              <Button
                color="primary"
                onClick={updateConstruction}
                disabled={loading}
              >
                Zapisz
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          py={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 2,
            },
          }}
        >
          <SearchBar
            initialValue={query.search}
            setValue={onQueryChange('search')}
          />
          <Box
            component={Paper}
            display="flex"
            alignItems="center"
            height="40px"
          >
            <Box px={2} py={1}>
              <InputBase
                value={newConstructionName}
                onChange={(e) => setNewConstructionName(e.target.value)}
              />
            </Box>
            <Button
              disabled={loading}
              sx={{
                height: '40px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
              variant="contained"
              onClick={createConstruction}
            >
              Stwórz
            </Button>
          </Box>
        </Box>
        {constructionResult && (
          <>
            <List>
              {constructionResult.items.map((construction) => {
                return (
                  <React.Fragment key={construction._id}>
                    <Divider />
                    <ListItem
                      sx={{ padding: 2 }}
                      secondaryAction={
                        <Box display="flex">
                          <IconButton
                            onClick={() => setUpdatedConstruction(construction)}
                          >
                            <EditIcon />
                          </IconButton>
                          <ConfirmationWrapper
                            title="Na pewno usunąć budowę?"
                            action={() => deleteConstruction(construction._id)}
                          >
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </ConfirmationWrapper>
                        </Box>
                      }
                    >
                      {construction.name}
                    </ListItem>
                  </React.Fragment>
                )
              })}
            </List>
            {constructionResult.items.length === 0 && (
              <Typography>Brak wyników</Typography>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
              {constructionResult.items.length > 0 && (
                <Pagination
                  count={Math.ceil(constructionResult.count / perPage)}
                  onChange={(e, page) =>
                    onQueryChange('offset')(perPage * (page - 1))
                  }
                  page={Math.floor(query.offset / perPage) + 1}
                  shape="rounded"
                />
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  )
}
